import type { ExtendedAsyncAPISpec, ExtendedOpenAPISpec } from '@newdaycards/spec-tools';
import { getAsyncApiNavigation, getOpenApiNavigation } from 'components/navigation/navigation-utils';
import type { PageProps } from 'gatsby';
import { useEffect } from 'react';
import { PageLayout } from '../layouts/page-layout';
import { ReferenceLayout } from '../layouts/reference';
import { DeprecationProvider } from '../providers/deprecation-provider';
import withAppInsights from '../utils/analytics/appinsights';

export interface SpecPageContext {
  publicURL: {
    openApi?: string;
    asyncApi?: string;
  };
  slug: string;
  spec: {
    openApi?: ExtendedOpenAPISpec;
    asyncApi?: ExtendedAsyncAPISpec;
  };
  pagePath: string;
  title: string;
  description: string;
  deprecated: boolean;
}

export function ReferenceTemplate({ pageContext, location }: PageProps<null, SpecPageContext>) {
  const { openApi, asyncApi } = pageContext.spec;
  const { title, description, pagePath, deprecated } = pageContext;

  useEffect(() => {
    if (!location.hash) return undefined;

    const timeout = setTimeout(() => {
      window.requestAnimationFrame(() => {
        const anchor = document.querySelector(location.hash.replace('/', ''));

        if (!anchor) return;

        const offset = anchor.getBoundingClientRect().top + window.scrollY;
        window.scroll({ top: offset - 20, left: 0 });
      });
    });

    return () => clearTimeout(timeout);
  });

  const specNavigation = {
    openApi: openApi
      ? getOpenApiNavigation({
          pagePath,
          spec: openApi,
        })
      : new Map(),
    asyncApi: asyncApi
      ? getAsyncApiNavigation({
          pagePath,
          spec: asyncApi,
        })
      : new Map(),
  };

  return (
    <DeprecationProvider>
      <PageLayout
        location={location}
        specNavigation={specNavigation}
        isLargeContent
        title={title}
        description={description}
        commitDate={openApi?.['x-pub-settings'].commitDate || asyncApi?.['x-pub-settings'].commitDate || undefined}
      >
        <ReferenceLayout
          spec={pageContext.spec}
          publicUrls={pageContext.publicURL}
          pagePath={pagePath}
          deprecated={deprecated}
        />
      </PageLayout>
    </DeprecationProvider>
  );
}

export default withAppInsights(ReferenceTemplate);
